var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"AddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.generateReport($event)}}},[_c('b-form-group',{attrs:{"label":"Report Name","label-for":"Report Name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Report Name")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Report Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","name":"report_name"},model:{value:(_vm.report_name),callback:function ($$v) {_vm.report_name=$$v},expression:"report_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Template","label-for":"Template"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Template")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"template_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.template_options,"state":errors.length > 0 ? false : null,"name":"template"},on:{"change":_vm.onTempChange},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._l((_vm.items),function(variable,key){return _c('b-form-group',{key:key,attrs:{"label":variable.variable_label},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(variable.variable_label))]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":variable.variable_label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(variable.variable_value),callback:function ($$v) {_vm.$set(variable, "variable_value", $$v)},expression:"variable.variable_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),_c('b-button',{staticClass:"float-right",attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v(" Generate Report")])])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }