<template>
  <b-card-code>
    <!-- <p>{{organization}}organisation</p> -->
    <b-button
      v-if="this.$store.state.app.user.is_staff"
      type="button"
      variant="success"
      class="btn ml-auto m-1"
      size="sm"
    >
      <router-link style="color: #ffff !important" :to="'/report/uploadReport'"
        >Upload Report</router-link
      >
    </b-button>
    <!-- <b-button
      v-if="this.$store.state.app.user.is_staff"
      type="button"
      variant="primary"
      class="btn ml-auto m-1"
      size="sm"
    >
      <router-link class="text-white" :to="'/report/report-log'"
        >Report Logs</router-link
      >
    </b-button> -->
    <b-tabs ref="tabs" v-model="tabIndex">
      <b-tab v-if="this.$store.state.app.user.is_staff" title="Reports">
        <b-card-code class="bg-light-secondary mb-1" title="Search">
          <b-form inline style="display: inline-block; width: 100%">
            <div class="row" style="width: 100%; padding: 5px">
              <!-- <v-select
            class="ml-1"
            v-if="is_staff"
            v-model="organization"
            label="text"
            placeholder="--Select Organization--"
            :options="organizations"
            @input="searchFn"
            autocomplete
            style="width: 30%"
            :reduce="organization => organization.value"
          /> -->

              <b-form-input
                class="ml-1"
                id="name"
                v-model="name_filter"
                style="width: 65%"
                name="name"
                placeholder="Search with name"
              />
              <b-button
                style="width: 12%"
                class="ml-1"
                variant="primary"
                @click="searchFn"
              >
                Search
              </b-button>
              <button
                v-if="
                  !$store.state.app.user.is_staff &&
                  $store.state.app.user.permissions.includes(
                    'report.generate_report'
                  )
                "
                @click="generateReport()"
                type="button"
                class="btn ml-auto btn-info"
              >
                Generate Report
              </button>
            </div>
          </b-form>
        </b-card-code>

        <div class="d-flex justify-content-center mb-1" v-if="loadingReport">
          <b-spinner class="float-right" label="Floated Right" />
        </div>
        <div v-if="!loadingReport">
          <b-table
            id="orgTable"
            :fields="fields"
            :items="items"
            :per-page="perPage"
            ref="table"
            v-if="items.length"
            hover
            style="min-height: 200px"
          >
            <template #cell(index)="data">
              {{ data.index + 1 + perPage * (currentPage - 1) }}
            </template>
            <!-- <template #cell(name)="data">
        {{ data.item.report_name.replace(/\.[^/.]+$/, "") }}
      </template> -->
            <template #cell(created_at)="data">
              {{ data.item.created_at | formatdate }}
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary" v-if="data.item.status == 0"
                >Scheduled</b-badge
              >
              <b-badge variant="warning" v-if="data.item.status == 1"
                >In Progress</b-badge
              >
              <b-badge variant="success" v-if="data.item.status == 2"
                >Generated</b-badge
              >
              <b-badge
                variant="danger"
                v-if="data.item.status == 3"
                style="cursor: pointer"
                :title="data.item.errors"
                >Failed</b-badge
              >
              <b-badge variant="success" v-if="data.item.status == 4"
                >Uploaded</b-badge
              >
            </template>
            <template #cell(actions)="data">
              <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
              <b-dropdown
                size="sm"
                class="ml-1"
                variant="outline-primary"
                text="Actions"
              >
                <b-dropdown-item
                  v-if="!data.item.is_published && data.item.status != 3"
                  @click="PublishReport(data.item.report_id)"
                  >Publish</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="
                    data.item.status == 2 && data.item.report_pdf_url != null
                  "
                  @click="
                    downloadPDFReport(
                      data.item.report_id,
                      data.item.report_name
                    )
                  "
                  >Download PDF Report</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.status != 3 && data.item.status != 0"
                  @click="
                    downloadReport(data.item.report_id, data.item.report_name)
                  "
                  >Download Report</b-dropdown-item
                >
                <b-dropdown-item @click="ReportLog(data.item.report_id)"
                  >Report Logs</b-dropdown-item
                >
                <!-- <b-dropdown-item @click="allocateScanners(data.item.org_id)">Default Scanners</b-dropdown-item> -->
                <b-dropdown-divider />
                <b-dropdown-item
                  @click="
                    gotoDelete(
                      data.item.report_id,
                      data.item.report_name.replace(/\.[^/.]+$/, '')
                    )
                  "
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <div v-else>
            <div
              class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
            >
              <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
              <h3 class="font-weight-bolder">No Data Found</h3>
            </div>
          </div>
        </div>

        <b-pagination-nav
          v-model="currentPage"
          :numberOfPages="total"
          :total-rows="rows"
          :link-gen="linkGen"
          :per-page="perPage"
          :align="pagination_pos"
          aria-controls="orgTable"
          use-router
        />
      </b-tab>
      <b-tab title="Published Reports" lazy>
        <div class="d-flex justify-content-center mb-1" v-if="loading">
          <b-spinner class="float-right" label="Floated Right" />
        </div>
        <div v-if="!loading">
          <b-table
            id="orgTable"
            :fields="publishfields"
            :items="publishitems"
            :per-page="perPage"
            ref="table"
            v-if="publishitems.length"
            hover
            style="min-height: 200px"
          >
            <template #cell(index)="data">
              {{ data.index + 1 + perPage * (currentPage - 1) }}
            </template>
            <!-- <template #cell(name)="data">
        {{ data.item.report_name.replace(/\.[^/.]+$/, "") }}
      </template> -->
            <template #cell(published_at)="data">
              {{ data.item.published_at | formatdate }}
            </template>
            <template #cell(status)="data">
              <b-badge variant="primary" v-if="data.item.status == 0"
                >Scheduled</b-badge
              >
              <b-badge variant="warning" v-if="data.item.status == 1"
                >In Progress</b-badge
              >
              <b-badge variant="success" v-if="data.item.status == 2"
                >Generated</b-badge
              >
              <b-badge variant="danger" v-if="data.item.status == 3"
                >Failed</b-badge
              >
              <b-badge variant="success" v-if="data.item.status == 4"
                >Uploaded</b-badge
              >
            </template>
            <template #cell(actions)="data">
              <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
              <b-dropdown
                size="sm"
                class="ml-1"
                variant="outline-primary"
                text="Actions"
              >
                <b-dropdown-item
                  v-if="!data.item.is_published && data.item.status != 3"
                  @click="PublishReport(data.item.report_id)"
                  >Publish</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="
                    data.item.status == 2 && data.item.report_pdf_url != null
                  "
                  @click="
                    downloadPDFReport(
                      data.item.report_id,
                      data.item.report_name
                    )
                  "
                  >Download PDF Report</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.status != 3 && data.item.status != 0"
                  @click="
                    downloadReport(data.item.report_id, data.item.report_name)
                  "
                  >Download Report</b-dropdown-item
                >
                <b-dropdown-item @click="ReportLog(data.item.report_id)"
                  >Report Logs</b-dropdown-item
                >
                <!-- <b-dropdown-item @click="allocateScanners(data.item.org_id)">Default Scanners</b-dropdown-item> -->
                <b-dropdown-divider />
                <b-dropdown-item
                  @click="
                    gotoPublishDelete(
                      data.item.report_id,
                      data.item.report_name.replace(/\.[^/.]+$/, '')
                    )
                  "
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <div v-else>
            <div
              class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
            >
              <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
              <h3 class="font-weight-bolder">No Data Found</h3>
            </div>
          </div>
        </div>

        <b-pagination-nav
          v-model="publishcurrentPage"
          :numberOfPages="publishtotal"
          :total-rows="publishrows"
          :link-gen="linkGen"
          :per-page="publishperPage"
          :align="pagination_pos"
          aria-controls="orgTable"
          use-router
        />
      </b-tab>
    </b-tabs>

    <b-modal
      ref="report_generator"
      title="Generate Report"
      hide-header
      hide-footer
      size="lg"
    >
      <ReportGenerator
        :report_type="parseInt(0)"
        :org_id="id"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ reportName }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteReport()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeletePublishModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete {{ reportName }} ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deletePublishReport()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable, BBadge } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportGenerator from "./../common/components/ReportGenerator.vue";
import moment from "moment";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BTab,
  BTabs,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    ReportGenerator,
    vSelect,
    BTab,
    BTabs,
    BCardText,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        // { key: "report_id", label: "#" },
        { key: "index", label: "#" },
        { key: "report_filename", label: "Name" },
        { key: "status", label: "Status" },
        { key: "created_at", label: "Created At" },
        { key: "generated_username", label: "Generated By" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      organization: this.$store.state.app.org_id,
      // organization: null,
      organizations: [],
      name_filter: "",
      loading: false,
      loadingReport: false,
      id: 0,
      reportName: "",
      report_id: "",
      openDeleteModal: false,
      openDeletePublishModal: false,

      publishitems: [],
      publishcurrentPage: 1,
      publishperPage: 10,
      publishrows: 0,
      publishtotal: 1,
      publishfields: [
        // { key: "report_id", label: "#" },
        { key: "index", label: "#" },
        { key: "report_filename", label: "Name" },
        { key: "status", label: "Status" },
        { key: "published_at", label: "Published At" },
        { key: "generated_username", label: "Generated By" },
        { key: "actions", label: "Actions" },
      ],
      tabIndex: 0,
    };
  },
  mounted() {
    this.setTabIndexFromPathQuery();
    if (this.organization != null) {
      this.load();
      // this.loadOrg();
      this.searchFn();
      this.loadPublish();
    }
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
    publishcurrentPage: function (newVal, oldVal) {
      this.publishsearchFn(false);
    },
  },
  filters: {
    formatdate: function (value) {
      // if (value) {
      //   return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      // }
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    setTabIndexFromPathQuery() {
      if (this.$route.query.tabIndex) {
        this.tabIndex = parseInt(this.$route.query.tabIndex) || 0;
        this.$router.replace({ query: null });
      }
    },
    // loadOrg: function () {
    //   if (this.is_staff) {
    //     this.loading = true;

    //     const o_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "organization/organization/get-all-org",
    //     };
    //     var self = this;
    //     this.$http(o_options).then((res) => {

    //       res.data.map(function (value, key) {
    //         let org = {
    //           value: res.data[key].org_id,
    //           text: res.data[key].org_name,
    //         };

    //         self.organizations.push(org);
    //       });
    //       this.loading = false;
    //     });
    //   } else {
    //     this.organization = this.$store.state.app.user.profile.organization;
    //     this.searchFn();
    //   }
    // },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report?org_id=" +
          this.organization +
          "&published=false",
      };
      this.$http(options).then((res) => {
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    loadPublish: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report?org_id=" +
          this.organization +
          "&published=true",
      };
      this.$http(options).then((res) => {
        // this.toastMessage(res)  // method call to method2
        this.publishitems = res.data.results;
        this.publishrows = res.data.results.length;
        this.publishtotal = Math.ceil(res.data.count / this.publishperPage);
      });
    },
    publishsearchFn: function (reset = true) {
      // this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.publishcurrentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "report/report?query=true" +
        "&page=" +
        this.publishcurrentPage +
        "&published=true";

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }

      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.publishitems = res.data.results;
        this.publishrows = res.data.results.length;
        this.publishtotal = Math.ceil(res.data.count / this.publishperPage);
        this.loading = false;
      });
    },
    searchFn: function (reset = true) {
      // this.is_search_on = false;
      this.loadingReport = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "report/report?query=true" +
        "&page=" +
        this.currentPage +
        "&published=false";

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loadingReport = false;
      });
    },
    deletePublishReport() {
      // this.reportName = report_name;
      // if (confirm("Do you really want to delete " + this.reportName + "?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "report/report/" + this.report_id + "/",
      };
      this.$http(options).then((res) => {
        this.openDeletePublishModal = false;
        this.loadPublish();
      });
      // }
    },
    gotoPublishDelete(report_id, report_name) {
      this.openDeletePublishModal = true;
      this.report_id = report_id;
      this.reportName = report_name;
    },
    gotoDelete(report_id, report_name) {
      this.openDeleteModal = true;
      this.report_id = report_id;
      this.reportName = report_name;
    },
    deleteReport() {
      // this.reportName = report_name;
      // if (confirm("Do you really want to delete " + this.reportName + "?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "report/report/" + this.report_id + "/",
      };
      this.$http(options).then((res) => {
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
    PublishReport(id) {
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report/" +
          id +
          "/publish-report/",
      };
      this.$http(options).then((res) => {
        if (res.data.status === "success") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.load();
        this.loadPublish();
      });
    },
    ReportLog(id) {
      this.$router.push({ name: "Report Logs", params: { id: id } });
    },
    downloadReport(id, name) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "report/report/" + id + "/download",
        responseType: "blob",
      };
      this.$http(options).then((res) => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Report download has been completed successfully.",
              icon: "DownloadIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "connection error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }

        // this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
    },
    downloadPDFReport(id, name) {
      var file_name = name.split(".")[0];
      var fileName = file_name + ".pdf";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report/" +
          id +
          "/download-as-pdf",
        responseType: "blob",
      };
      this.$http(options).then((res) => {
        if (res.status === 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "connection error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "PDF report download has been completed successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    generateReport() {
      if (
        confirm(
          "This action will generate a VAPT report for the whole organization.Do you want to proceed?"
        )
      ) {
        // let id = this.$store.state.app.user.profile.organization;
        let id = this.$store.state.app.org_id;
        this.id = id;
        this.$refs["report_generator"].show();
      }
    },
    closeModal() {
      this.$refs["report_generator"].hide();
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
