<template>
  <div>
    <!-- {{org_ref_id}} -->
    <validation-observer ref="AddForm" #default="{ invalid }">
      <b-form class="auth-login-form" @submit.prevent="generateReport">
        <b-form-group label="Report Name" label-for="Report Name">
          <template #label>
            <span>Report Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Report Name"
            rules="required"
          >
            <b-form-input
              v-model="report_name"
              type="text"
              name="report_name"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- ------------- project and activity commented------------------------ -->

        <!-- <b-form-group label="Project" label-for="project" v-if="is_staff">
          <b-form-select
            v-model="project"
            :options="projects"
            name="project"
            @change="loadActivities"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Activity" label-for="Activity" v-if="is_staff">
          <b-form-select
            v-model="activity"
            :options="activity_options"
            name="Activity"
          ></b-form-select>
        </b-form-group> -->

        <b-form-group label="Template" label-for="Template">
          <template #label>
            <span>Template</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="template_name"
            rules="required"
          >
            <b-form-select
              v-model="template"
              :options="template_options"
              :state="errors.length > 0 ? false : null"
              name="template"
              @change="onTempChange"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          v-for="(variable, key) in items"
          :key="key"
          :label="variable.variable_label"
        >
          <template #label>
            <span>{{ variable.variable_label }}</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            :name="variable.variable_label"
            rules="required"
          >
            <b-form-input v-model="variable.variable_value" type="text">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          class="float-right"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else> Generate Report</span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardCode,
    BCard,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    org_id: Number,
    org_ref_id: String,
    scan_id: Number,
    asset_id: Number,
    group_id: Number,
    report_type: Number,
    parentCallBack: Function,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      template_options: [],
      template: null,
      report_name: "",
      items: [],
      activity_options: [],
      activity: "",
      projects: [],
      project: "",
      isloading: false,
    };
  },
  created: function () {
    this.load();
    this.loadProject();
    // this.loadActivities();
  },
  methods: {
    load: function () {
      this.template_options = [];
      let url =
        process.env.VUE_APP_BASEURL +
        "report/report-template/get-all-templates?limit=0&offset=0";
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.map(function (value, key) {
          let opt = {
            value: res.data[key].template_id,
            text: res.data[key].template_name,
          };
          // console.log(res.data[key].profile.organization)
          self.template_options.push(opt);
        });
      });
    },
    loadProject: function () {
      let url =
        process.env.VUE_APP_BASEURL +
        "project/project/?org_id=" +
        this.org_ref_id;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.map(function (value, key) {
          let pro = {
            value: res.data[key].project_id,
            text: res.data[key].project_name,
          };
          self.projects.push(pro);
        });
      });
    },
    loadActivities: function () {
      let url =
        process.env.VUE_APP_BASEURL +
        "project/activity/?project_id=" +
        this.project;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.map(function (value, key) {
          let opt = {
            value: res.data[key].activity_id,
            text: res.data[key].activity_name,
          };
          self.activity_options.push(opt);
        });
      });
    },
    onTempChange: function () {
      this.items = [];
      // Load template properties
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "report/report-template/" +
          this.template,
      };
      var self = this;
      this.$http(options).then((res) => {
        let meta = JSON.parse(res.data.template_meta_fields);
        // self.items = meta
        meta.forEach(function (item, index) {
          let i = {
            id: item.id,
            variable: item.variable,
            variable_label: item.variable_label,
            variable_value: "",
          };
          self.items.push(i);
        });
      });
    },
    generateReport: function () {
      this.$refs.AddForm.validate().then((success) => {
        this.isloading = true;
        if (success) {
          var name = this.report_name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
          let meta = {};
          if (this.report_type == 0)
            meta = '{"org_id":' + this.org_id + ',"format":"doc"}';
          if (this.report_type == 1)
            meta = '{"asset_id":' + this.asset_id + ',"format":"doc"}';
          if (this.report_type == 2)
            meta = '{"group_id":' + this.group_id + ',"format":"doc"}';
          if (this.report_type == 3)
            meta = '{"scan_id":' + this.scan_id + ',"format":"doc"}';
          let data = {
            report_type: this.report_type,
            report_name: name + ".docx",
            meta: meta,
            activity_id: this.activity,
            generated_by: this.$store.state.app.user.id,
            template: this.template,
            template_meta_data: JSON.stringify(this.items),
          };
          const options = {
            method: "POST",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_BASEURL + "report/report/",
          };
          var self = this;
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.report_id) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Request for report accepted. Check the reports area for updates",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.push('/reports');
            } else
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something wrong happened. Please try again later",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            this.parentCallBack();
            this.isloading = false;
          });
          // }
        }
      });
    },
  },
};
</script>